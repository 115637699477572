import axios from 'axios'
import React, { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import { BadUrl, Header, Home, HomeHAC } from './modules/home/components'
import { Profile } from './modules/profile/components'
import { Search, SearchHAC } from './modules/search/components'

import './App.scss'
import SearchRefactor from './modules/searchRefactor'

class App extends Component {
  /**
   * Note: There's currently no clientside datastore in CYM,
   * so all of the search field / filter state lives here. When
   * you're down in the search components inside of <Home/> and
   * <Search/> know that that data is sourced from this
   * component's state.
   *
   * The search results state lives in the <Search/> component.
   *
   * @memberof App
   */
  state = {
    skills: [],
    allSkills: [],
    work_options: [],
    allWorkOptions: [],
    course_preferences: [],
    allCoursePreferences: [],
    industries: [],
    all_industries: [],
    business_types: [],
    allBusinessTypes: [],
    services: [],
    allServices: [],
    certs: [],
    allCerts: [],
    lat: '',
    lng: '',
    budget: '',
    query: '',
    name: '',
    country: '',
    locationDisplayName: '',
    locationData: null,
    locationAttempted: false,
    showFooter: true,
    appName: process.env.REACT_APP_NAME,
  }

  componentDidMount = () => {
    this.loadCertsList()

    // MMS only
    this.loadSkillsList()

    // HAC Only
    this.loadCoachFilters()
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      //For HubSpot data
      const _hsq = (window._hsq = window._hsq || [])

      // HubSpot Tracking on each history change
      _hsq.push(['setPath', location.pathname])
      _hsq.push(['trackPageView'])
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  loadSkillsList = () => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + '/api/skills')
      .then(response => {
        let allSkills = response.data
        allSkills.push({ id: '', name: 'All Skills' })
        this.setState({ allSkills })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  loadCoachFilters = () => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + '/api/coach_filters')
      .then(response => {
        let allWorkOptions = response.data.work_options
        let allServices = response.data.services
        let allCoursePreferences = response.data.course_preferences
        let allIndustries = response.data.industries
        let allBusinessTypes = response.data.business_types
        // res.push({ id: '', name: 'All Skills' })
        this.setState({ allWorkOptions, allServices, allCoursePreferences, allIndustries, allBusinessTypes })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  loadCertsList = () => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + '/api/certifications')
      .then(response => {
        let allCerts = response.data
        this.setState({ allCerts })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  onFilterChange = (filter, value) => {
    const obj = {}
    obj[filter] = value
    return new Promise(resolve => this.setState(obj, resolve))
  }

  setFooterVisibility = showFooter => {
    this.setState({
      showFooter,
    })
  }

  render() {
    const isMMS = this.state.appName === 'hac' ? false : true
    // const isIE = /*@cc_on!@*/ false || !!document.documentMode

    return (
      <div
        className={
          'App' + (this.props.location.pathname === '/' || this.props.location.pathname === '/podcast' ? ' home' : '')
        }
        data-app={isMMS ? 'mms' : 'hac'}
      >
        <Header isMMS={isMMS} router={this.props.history} />
        <div className="App-wrap">
          <Switch>
            <Route
              render={() =>
                isMMS ? (
                  <Home onFilterChange={this.onFilterChange} filters={this.state} router={this.props.history} />
                ) : (
                  <HomeHAC onFilterChange={this.onFilterChange} filters={this.state} router={this.props.history} />
                )
              }
              path="/"
              exact
            />
            <Route
              render={() =>
                isMMS ? (
                  <Home onFilterChange={this.onFilterChange} filters={this.state} router={this.props.history} />
                ) : (
                  <HomeHAC onFilterChange={this.onFilterChange} filters={this.state} router={this.props.history} />
                )
              }
              path="/podcast"
              exact
            />
            <Route render={() => <SearchRefactor />} path="/search" exact />
            <Route
              render={() =>
                isMMS ? (
                  <Search
                    onFilterChange={this.onFilterChange}
                    setFooterVisibility={this.setFooterVisibility}
                    filters={this.state}
                    isMMS={isMMS}
                  />
                ) : (
                  <SearchHAC
                    onFilterChange={this.onFilterChange}
                    setFooterVisibility={this.setFooterVisibility}
                    filters={this.state}
                    isMMS={isMMS}
                    isAdmin={true}
                  />
                )
              }
              path="/admin/search"
              exact
            />
            <Route
              render={() =>
                isMMS ? (
                  <Search
                    onFilterChange={this.onFilterChange}
                    setFooterVisibility={this.setFooterVisibility}
                    filters={this.state}
                    isMMS={isMMS}
                  />
                ) : (
                  <SearchHAC
                    onFilterChange={this.onFilterChange}
                    setFooterVisibility={this.setFooterVisibility}
                    filters={this.state}
                    isMMS={isMMS}
                    coachConsult={true}
                    router={this.props.history}
                  />
                )
              }
              path="/search/coach-consult"
              exact
            />
            <Route
              render={() =>
                isMMS ? (
                  <Search
                    onFilterChange={this.onFilterChange}
                    setFooterVisibility={this.setFooterVisibility}
                    filters={this.state}
                    isMMS={isMMS}
                  />
                ) : (
                  <Search
                    onFilterChange={this.onFilterChange}
                    setFooterVisibility={this.setFooterVisibility}
                    filters={this.state}
                    isMMS={isMMS}
                    nameOnly={true}
                  />
                )
              }
              path="/name"
              exact
            />
            <Route
              path="/customer-testimonials/:id"
              render={props => {
                window.location = 'https://admin.marketingmadesimple.com/customer-testimonials/' + props.match.params.id
              }}
            />
            <Route
              path="/sign-up/:id"
              render={props => {
                window.location = 'https://admin.marketingmadesimple.com/sign-up/' + props.match.params.id
              }}
            />
            <Route path="/404" component={BadUrl} exact />
            <Route path="/:slug" render={props => <Profile isMMS={isMMS} {...props} />}></Route>
          </Switch>
        </div>
        {this.state.showFooter && (
          <footer class="">
            <div class="mx-auto" style={{ backgroundColor: '#000' }}>
              <div class="flex flex-col lg:flex-row items-center justify-between gap-8 p-8 text-white">
                <figure>
                  <img src="https://coachbuilder.com/wp-content/uploads/2024/06/Layer-1.svg" alt="" />
                </figure>
                <nav>
                  <ul class="list-none flex flex-wrap items-center justify-center gap-x-8 gap-y-4 mb-0 pl-0">
                                        <li class="!p-0 !m-0">
                            <a class="text-white !font-regular" href="https://admin.coachbuilder.com/login" target="_blank">
                              Log In                  </a>
                          </li>
                      

                      
                                        <li class="!p-0 !m-0">
                            <a class="text-white !font-regular" href="https://coachbuilder.com/book/" target="">
                              Coach Builder Book                  </a>
                          </li>
                      

                      
                                        <li class="!p-0 !m-0">
                            <a class="text-white !font-regular" href="https://storybrand.com/contact?__hstc=216253897.14e641ced0100e84f7c93f0d73132032.1714586820702.1721237995880.1721263764452.56&amp;__hssc=216253897.1.1721263764452&amp;__hsfp=4274120786" target="_blank">
                              Contact                  </a>
                          </li>
                      

                      
                                        <li class="!p-0 !m-0">
                            <a class="text-white !font-regular" href="https://businessmadesimple.com/digital-millennium-copyright-act-policy?__hstc=216253897.14e641ced0100e84f7c93f0d73132032.1714586820702.1721237995880.1721263764452.56&amp;__hssc=216253897.1.1721263764452&amp;__hsfp=4274120786" target="_blank">
                              DMCA                  </a>
                          </li>
                      

                      
                    
                  </ul>
                </nav>
              </div>
              <div class="text-center text-copyMedium text-sm p-6 flex flex-col lg:flex-row items-center justify-center gap-x-8 gap-y-2">
                <p class="text-sm mb-0 text-white">Copyright © 2024 Donald Miller Words, LLC. All rights reserved. <a class="font-medium text-copy text-white" href="tel:+615-915-1541 ">615-915-1541</a></p>
                <p class="text-sm mb-0 text-white font-bold"><a href="https://coachbuilder.com/terms-and-conditions/" class="font-bold underline text-white">Terms and Conditions</a> / <a href="https://coachbuilder.com/privacy-policy/" class="font-bold underline text-white">Privacy Policy</a></p>
                <p class="text-sm mb-0 text-white font-bold"><a href="https://coachbuilder.com/online-services-cancellation-policy/" class="font-bold underline text-white">Online Services Cancellation Policy</a></p>
              </div>
            </div>
          </footer>
        )}
      </div>
    )
  }
}

export default withRouter(App)
