import React, { useEffect, useState } from 'react'
import { ProgressBar, QuestionBox, SendToCoaches } from '../components'

const GuidedSearch = ({
  nameOnly,
  filters,
  onFilterChange,
  loadProfiles,
  renderProfiles,
  renderLoadingIndicator,
  handleNext,
  handlePrev,
  handleSelectedContact,
  selectedContacts,
  previouslySelectedContacts,
  nextPage,
  prevPage,
  isMMS,
  batch,
  loading,
  setPreviouslySelectedContacts,
  coachConsult,
  router,
  isAdmin,
}) => {
  const questions = [
    'How do you want to work with a coach?',
    'What type of experience are you looking for?',
    'What is your primary reason(s) for hiring a business coach?',
    'Which of the following best describes the area you work in or your business?',
    'Which of the following best describes the type of business you have?',
    coachConsult
      ? 'Select one coach to arrange a free 30 minute consultation'
      : "Select the coaches you'd like to contact you about working together",
    coachConsult ? 'Send your info to the coach!' : 'Send your info to the coaches!',
  ]

  const stepsCount = 7
  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState(null)

  const nextStep = () => {
    step < stepsCount && setStep(step + 1)
  }

  const previousStep = (reset = false) => {
    if (reset) {
      onFilterChange('name', '')
    }
    step > 1 && setStep(step - 1)
  }

  const sendToMoreCoaches = () => {
    if (coachConsult) {
      router.push('/')
    } else {
      setStep(6)
      setPreviouslySelectedContacts()
    }
  }

  const handleSearch = e => {
    e.preventDefault()
    loadProfiles()
    setStep(6)
  }

  const questionFilters = () => {
    switch (step) {
      case 1:
        return { all: 'allWorkOptions', selected: 'work_options' }
      case 2:
        return { all: 'allServices', selected: 'services' }
      case 3:
        return { all: 'allCoursePreferences', selected: 'course_preferences' }
      case 4:
        return { all: 'allIndustries', selected: 'industries' }
      case 5:
        return { all: 'allBusinessTypes', selected: 'business_types' }
      default:
        return { all: null, selected: null }
    }
  }

  useEffect(() => {
    if (step === 6 && !nameOnly) {
      loadProfiles()
    }
  }, [step])

  return (
    <div id="Guided-Search">
      <div className="pw Guided-search-inner">
        {nameOnly ? null : <ProgressBar step={step} stepsCount={stepsCount} setStep={setStep} isAdmin={isAdmin} />}
        {step < 7 ? (
          <QuestionBox
            isMMS={isMMS}
            nameOnly={nameOnly}
            step={step}
            stepsCount={stepsCount}
            questions={questions}
            nextStep={nextStep}
            previousStep={previousStep}
            options={filters[questionFilters().all]}
            selectedOptions={filters[questionFilters().selected]}
            filter={questionFilters().selected}
            filters={filters}
            onFilterChange={onFilterChange}
            renderProfiles={renderProfiles}
            renderLoadingIndicator={renderLoadingIndicator}
            handleNext={handleNext}
            handlePrev={handlePrev}
            selectedContacts={selectedContacts}
            previouslySelectedContacts={previouslySelectedContacts}
            nextPage={nextPage}
            prevPage={prevPage}
            loading={loading}
            handleSearch={handleSearch}
            coachConsult={coachConsult}
          />
        ) : null}
        {step === 7 && (
          <SendToCoaches
            step={step}
            stepsCount={stepsCount}
            questions={questions}
            filter={questionFilters().selected}
            filters={filters}
            renderProfiles={renderProfiles}
            handleSelectedContact={handleSelectedContact}
            selectedContacts={selectedContacts}
            previouslySelectedContacts={previouslySelectedContacts}
            previousStep={previousStep}
            isMMS={isMMS}
            batch={batch}
            sendToMoreCoaches={sendToMoreCoaches}
            formData={formData}
            setFormData={setFormData}
            coachConsult={coachConsult}
          />
        )}
        {/* <button onClick={loadProfiles}>search</button> */}
      </div>
    </div>
  )
}

export default GuidedSearch
