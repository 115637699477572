import * as React from 'react'

export default function Testimonial(props) {
  return (
    <div className="bg-white rounded-md shadow-md Testimonial">
      <div className="p-6 border-b border-gray-200 border-solid Card__details">
        <div className="Card__attribution">
          <span className="block mb-2 text-lg font-bold leading-4">{props.testimony.customer_name}</span>
          <span className="block text-xs font-bold tracking-widest text-gray-400 uppercase">
            {props.testimony.company_name}
          </span>
        </div>
      </div>
      <div className="p-6 Card__content">
        <p className="mb-0 text-sm">{props.testimony.review}</p>
      </div>
    </div>
  )
}
