import * as React from 'react'
import { Component } from 'react'
import { Link, useLocation } from 'react-router-dom'

const LogoDark = () => (
  <svg
    width="174"
    height="20"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 174.3 21.9"
  >
    <defs></defs>
    <g>
      <path
        class="cls-2 fill-current text-black stroke-0"
        d="M4.5,1.3h3.6s0,0,0,0v6.5s0,0,0,0h6.7s0,0,0,0V1.4s0,0,0,0h3.6s0,0,0,0v16.7s0,0,0,0h-3.6s0,0,0,0v-6.6s0,0,0,0h-6.7s0,0,0,0v6.6s0,0,0,0h-3.6s0,0,0,0V1.4s0,0,0,0Z"
      />
      <path class="fill-current text-black stroke-0" d="M23,1.3h3.6s0,0,0,0v16.7s0,0,0,0h-3.6s0,0,0,0V1.4s0,0,0,0Z" />
      <path
        class="fill-current text-black stroke-0"
        d="M30.9,1.4s0,0,0,0h7.6c2.1,0,3.8.6,4.9,1.7s1.4,2.3,1.4,3.9h0c0,2.7-1.4,4.4-3.5,5.2s0,0,0,.1l4,5.8c0,0,0,.1,0,.1h-4.2s0,0,0,0l-3.6-5.3s0,0,0,0h-2.8s0,0,0,0v5.2s0,0,0,0h-3.6s0,0,0,0V1.4ZM38.4,9.5c1.8,0,2.8-1,2.8-2.4h0c0-1.6-1.1-2.5-2.9-2.5h-3.6s0,0,0,0v4.7s0,0,0,0h3.7Z"
      />
      <path
        class="fill-current text-black stroke-0"
        d="M48.4,1.3h12.6s0,0,0,0v3.1s0,0,0,0h-8.9s0,0,0,0v3.3s0,0,0,0h7.8s0,0,0,0v3.1s0,0,0,0h-7.8s0,0,0,0v3.4s0,0,0,0h9s0,0,0,0v3.1s0,0,0,0h-12.7s0,0,0,0V1.4s0,0,0,0Z"
      />
    </g>
    <path
      class="fill-current text-black stroke-0"
      d="M71.2,4.5h1.8s0,0,0,0l3.9,9.2s0,0,0,0h-2s0,0,0,0l-.8-2s0,0,0,0h-3.8s0,0,0,0l-.8,2s0,0,0,0h-2s0,0,0,0l3.9-9.2s0,0,0,0ZM73.3,9.9l-1.2-2.8s0,0,0,0l-1.2,2.8s0,0,0,0h2.3s0,0,0,0Z"
    />
    <g>
      <path
        class="fill-current text-black stroke-0"
        d="M82.2,9.8h0c0-4.8,3.6-8.8,8.8-8.8s5,1,6.6,2.6c0,0,0,0,0,.1l-2.3,2.6s0,0-.1,0c-1.3-1.2-2.6-1.9-4.3-1.9-2.8,0-4.9,2.4-4.9,5.3h0c0,3.1,2.7,5.8,5.8,5.3,1.4-.2,2.4-.9,3.4-1.9s0,0,.1,0l2.3,2.3s0,0,0,.1c-1.7,1.8-3.6,3-6.9,3-5,0-8.7-3.8-8.7-8.7Z"
      />
      <path
        class="fill-current text-black stroke-0"
        d="M99.6,9.8h0c0-4.8,3.8-8.8,9-8.8s8.9,3.9,8.9,8.7h0c0,4.8-3.8,8.8-9,8.8s-8.9-3.9-8.9-8.7ZM113.6,9.8h0c0-2.9-2.1-5.4-5.1-5.4s-5.1,2.4-5.1,5.3h0c0,2.9,2.1,5.4,5.1,5.4s5.1-2.4,5.1-5.3Z"
      />
      <path class="fill-current text-black stroke-0" d="M127.2,5.6l-5.1,12.5h-3.8l7.2-17h3.4l7.2,17h-3.9l-5.1-12.5Z" />
      <path
        class="fill-current text-black stroke-0"
        d="M137,9.8h0c0-4.8,3.6-8.8,8.8-8.8s5,1,6.6,2.6c0,0,0,0,0,.1l-2.3,2.6s0,0-.1,0c-1.3-1.2-2.6-1.9-4.3-1.9-2.8,0-4.9,2.4-4.9,5.3h0c0,3.1,2.7,5.8,5.8,5.3,1.4-.2,2.4-.9,3.4-1.9s0,0,.1,0l2.3,2.3s0,0,0,.1c-1.7,1.8-3.6,3-6.9,3-5,0-8.7-3.8-8.7-8.7Z"
      />
      <path
        class="fill-current text-black stroke-0"
        d="M155.6,1.3h3.6s0,0,0,0v6.5s0,0,0,0h6.7s0,0,0,0V1.4s0,0,0,0h3.6s0,0,0,0v16.7s0,0,0,0h-3.6s0,0,0,0v-6.6s0,0,0,0h-6.7s0,0,0,0v6.6s0,0,0,0h-3.6s0,0,0,0V1.4s0,0,0,0Z"
      />
      <polygon class="cls-1 fill-current text-cbPink stroke-0" points="131 20.9 127.2 11.5 123.4 20.9 131 20.9" />
    </g>
  </svg>
)
function HACHeader({ calculateSearchLink, handleClose }) {
  //use the location to flip the find your coach button styles
  const location = useLocation()

  return (
    <div data-st="st-six" role="menu">
      <nav data-app={'hac'}>
        <ul>
          <li className="bg-white rounded-l-full p-5" style={{ marginRight: 0 }}>
            <a
              style={{ color: '#2d3354', fontWeight: '800' }}
              href={'https://www.coachbuilder.com'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Coach Builder
            </a>
          </li>
          <li className="bg-white p-5" style={{ marginRight: 0, color: '#2d3354' }}>
            <a
              style={{ color: '#2d3354', fontWeight: '800' }}
              href={process.env.REACT_APP_ADMIN_LOCATION + '/login'}
              target="_blank"
            >
              Login
            </a>
          </li>
          <li className="bg-white p-5 rounded-r-full">
            <Link
              style={{
                borderBottom: '',
                border: 'none',
                fontWeight: '700',
                marginRight: location.pathname === '/' ? '-11px' : '0px',
              }}
              to={calculateSearchLink()}
              onClick={handleClose}
              role="button"
              className={location.pathname === '/' ? 'cbBtn-pill p-4 rounded-full hover-none border-0' : 'largeBtn'}
            >
              Find Your Coach
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

function MMSHeader({ handleClose }) {
  return (
    <div data-st="st-six" role="menu">
      <nav data-app={'mms'}>
        <ul>
          <li>
            <a href="https://storybrand.com/sales-funnel-plan/" target="_blank" rel="noopener noreferrer">
              Download <span>The</span> Sales Funnel <span>Plan</span>
            </a>
          </li>

          <li>
            <a href="https://storybrand.com/guide/" target="_blank" rel="noopener noreferrer">
              Get Certified
            </a>
          </li>
          <li>
            <a href={process.env.REACT_APP_ADMIN_LOCATION + '/login'} target="_blank" rel="noopener noreferrer">
              Login
            </a>
          </li>
          <li>
            <Link to="/search" onClick={handleClose} role="button">
              Search <span>Now</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hamburgered: false,
    }
  }

  handleToggle = () => {
    this.setState({ hamburgered: !this.state.hamburgered })
  }

  handleClose = () => {
    this.setState({ hamburgered: false })
  }

  render() {
    const { isMMS } = this.props

    const calculateSearchLink = () => {
      const { router } = this.props
      try {
        if (router.location.pathname.indexOf('podcast') > -1) {
          return '/search/coach-consult'
        }
      } catch {
        //
      }
      return '/search'
    }

    return (
      <header className={'cf ' + (this.state.hamburgered ? 'hamburgered' : '')}>
        <div data-st="st-row" className="pw menu-wrapper">
          <div data-st="st-six" className="nav-left">
            <Link to="/">{this.props.router.location.pathname !== '/' && <LogoDark />}</Link>
            <a href="#" id="toggle" onClick={this.handleToggle}>
              <div className="patty" data-app={isMMS ? 'mms' : 'hac'}></div>
            </a>
          </div>
          {isMMS ? (
            <MMSHeader handleClose={this.handleClose} />
          ) : (
            <HACHeader handleClose={this.handleClose} calculateSearchLink={calculateSearchLink} />
          )}
        </div>
      </header>
    )
  }
}

export default Header

//TODO:darken gradient
