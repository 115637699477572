import axios from 'axios'
import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { enableChat } from '../../../utility/chat'
import Button from '../../shared/Button'
import { Testimonial } from '../components'

const isMMS = process.env.REACT_APP_NAME === 'mms'

const homePageLoadEventArguments = {
  dataLayer: {
    event: 'homePageLoad',
  },
  dataLayerName: 'CYMDataLayer',
}
const tagManagerArguments = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: 'CYMDataLayer',
}

class Home extends Component {
  constructor(props) {
    super(props)

    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }

    TagManager.initialize(tagManagerArguments)
    TagManager.dataLayer(homePageLoadEventArguments)
    enableChat()

    this.state = { filters: {} }
  }

  onSkillClick = selectedSkill => {
    const skillId = this.props.filters.allSkills.filter(skill => selectedSkill === skill.name)
    if (skillId.length > 0) {
      this.props.onFilterChange('skills', [skillId[0].id.toString()])
    }
    this.handleSearch()
  }

  onBlockClick = filter => {
    const filterInState =
      filter.type in this.state.filters &&
      this.state.filters[filter.type].find(f => f.name.toLowerCase() === filter.name.toLowerCase())
    if (filterInState) {
      this.props.router.push(
        {
          pathname: '/search',
        },
        {
          preSelectedFilters: {
            ...filterInState,
            type: filter.type,
            name: filter.name,
          },
        }
      )
    } else {
      this.props.router.push(
        {
          pathname: '/search',
        },
        {
          preSelectedFilters: { type: filter.type, name: filter.name },
        }
      )
    }
  }

  handleSearch = () => this.props.router.push('/search')

  componentDidMount() {
    this.props.onFilterChange('skills', [])
    this.props.onFilterChange('budget', '')
    this.props.onFilterChange('query', '')
    this.props.onFilterChange('lat', '')
    this.props.onFilterChange('lng', '')
    this.props.onFilterChange('locationDisplayName', '')

    axios
      .get(`${process.env.REACT_APP_API_LOCATION}/api/coach_filters_new?site=${isMMS ? 'mms' : 'hac'}`)
      .then(response => {
        this.setState({
          filters: response.data,
        })
      })
      .catch(e => console.log(e))
  }
  render() {
    return (
      <div className="Home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Marketing Made Simple | Home</title>
        </Helmet>
        <section className="splash" role="banner">
          <div className="pw" data-st="st-row">
            <div className="splash__content">
              <h1>Hire an Expert to Create your Marketing</h1>
              <Button text="Search Now" to="/search" className="Button--default Button--pink Default-spacing">
                Search Now
              </Button>
              <h5 className="tagline">StoryBrand Certified Agencies and Guides know how to grow your business</h5>
            </div>
            <div className="splash__image"></div>
          </div>
        </section>

        <section className="values" role="banner" data-st="st-row">
          <div data-st="st-three">
            <a
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Website Development',
                })
              }
            />
            <strong>Web Designers</strong>
          </div>
          <div data-st="st-three">
            <a
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Copywriting',
                })
              }
            />
            <strong>Copywriters</strong>
          </div>

          <div data-st="st-three">
            <a
              onClick={() =>
                this.onBlockClick({
                  type: 'certifications',
                  name: 'agency',
                })
              }
            />
            <strong>Marketing Agencies</strong>
          </div>
          <div data-st="st-three">
            <a
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Email Marketing',
                })
              }
            />
            <strong>Email Marketers</strong>
          </div>
        </section>

        <section className="growth" role="banner">
          <div className="pw" data-st="st-row">
            <div data-st="st-twelve">
              <h2>How can we help you grow your business?</h2>
            </div>
          </div>
          <div className="pw" data-st="st-row">
            <div
              data-st="st-three"
              className="card"
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Copywriting',
                })
              }
            >
              <h3>Copywriting</h3>
              <p>Whether it’s a sales letter, a brochure or a lead generator, our copywriters get results.</p>
              <a role="button" className="ctaButton">
                See Experts
              </a>
            </div>
            <div
              data-st="st-three"
              className="card"
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Sales Funnel Creation',
                })
              }
            >
              <h3>Sales Funnel Creation</h3>
              <p>
                A sales funnel created by a Certified Guide is proven to generate leads, close sales and increase
                revenue for your business while you sleep.
              </p>
              <a role="button" className="ctaButton">
                See Experts
              </a>
            </div>
            <div
              data-st="st-three"
              className="card"
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Website Development',
                })
              }
            >
              <h3>Website Development</h3>
              <p>
                From copywriting to design to full site development, a StoryBrand Certified Guide can create a website
                for you that will actually grow your business.
              </p>
              <a role="button" className="ctaButton">
                See Experts
              </a>
            </div>
            <div
              data-st="st-three"
              className="card"
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Overall Marketing Strategy',
                })
              }
            >
              <h3>Overall Marketing Strategy</h3>
              <p>Looking for a coach to help guide your marketing strategy and development? These experts can help.</p>
              <a role="button" className="ctaButton">
                See Experts
              </a>
            </div>
            <div
              data-st="st-three"
              className="card"
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Email Marketing',
                })
              }
            >
              <h3>Email Marketing</h3>
              <p>These Guides can write email copy, develop a campaign and implement it into your CRM of choice.</p>
              <a role="button" className="ctaButton">
                See Experts
              </a>
            </div>
            <div
              data-st="st-three"
              className="card"
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Full Suite Marketing Agency',
                })
              }
            >
              <h3>Full Suite Marketing Agency</h3>
              <p>Hire an entire StoryBrand Certified team to execute a complete marketing strategy. </p>
              <a role="button" className="ctaButton">
                See Experts
              </a>
            </div>
          </div>
        </section>

        <section className="peoplelikeyou" role="banner">
          <div className="pw" data-st="st-row">
            <div data-st="st-twelve">
              <h2>People just like you have seen their business grow after hiring a StoryBrand Certified Expert</h2>
            </div>
          </div>
          <div className="pw" data-st="st-row">
            <div data-st="st-four" className="iconic">
              <h3>Search</h3>
              <p>Find the provider that’s right for your needs and your industry</p>
            </div>
            <div data-st="st-four" className="iconic">
              <h3>Hire Your Expert</h3>
              <p>Start your next marketing project with the guidance and help of a marketing expert</p>
            </div>
            <div data-st="st-four" className="iconic">
              <h3>Get Results</h3>
              <p>See results happen when you start using the right words and strategy</p>
            </div>
          </div>
          <div className="pw" data-st="st-row">
            <div data-st="st-twelve">
              <Link role="button" className="largeBtn" to="/search">
                Find an Expert
              </Link>
            </div>
          </div>
        </section>

        <section className="testimonials" role="banner">
          <div className="pw" data-st="st-row">
            <h2>Don't leave your marketing efforts to chance</h2>
            <h4>Hire an expert and hand your marketing over to someone you can trust.</h4>
          </div>
          <div className="pw" data-st="st-row">
            <Testimonial author="Marcia M.">
              Their expertise in setting up our Facebook Ad campaigns is fantastic. The results we have had from using
              their expertise has been outstanding!
            </Testimonial>
            <Testimonial author="Hilary W.">
              My website needed a major overhaul. My StoryBrand Certified Guide walked me through the process and made
              my website something I was proud of. My online sales are the highest they’ve ever been.
            </Testimonial>
            <Testimonial author="Nathan M.">
              This year, we partnered with a StoryBrand Certified Agency on two digital projects. The results have been
              literally beautiful and fruitful for our business..
            </Testimonial>
          </div>
          <div className="pw" data-st="st-row">
            <div data-st="st-twelve">
              <Link role="button" className="largeBtn" to="/search">
                Find an Expert
              </Link>
            </div>
          </div>
        </section>

        <section className="freelancer" role="banner">
          <div className="pw" data-st="st-row">
            <div data-st="st-six">
              <img src={require('../../../assets/freelancerpromo.png').default} />
            </div>
            <div data-st="st-six">
              <h2>Are you a marketing professional looking to get certified?</h2>
              <p>
                Do you enjoy getting serious results for your clients? If you have more than 2 years of marketing
                experience and want the opportunity to use the StoryBrand framework with your clients,{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://storybrand.com/guide/">
                  apply here
                </a>
                .
              </p>
              <a
                role="button"
                className="largeBtn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://storybrand.com/guide/"
              >
                Apply Now
              </a>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
export default Home
