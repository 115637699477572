import React, { Component } from 'react'

import Button from './Button'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'

export default class FormLocation extends Component {
  constructor(props) {
    super(props)

    this.formLocation = React.createRef()
    this.inputRef = React.createRef()
  }

  state = {
    value: null,
  }

  handleClick = e => {
    if (!this.props.isActive) return
    if (this.formLocation && !this.formLocation.current.contains(e.target)) {
      this.props.setActive('')
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick)
  }

  handleClear = () => {
    this.props.onFilterChange('lat', '')
    this.props.onFilterChange('lng', '')
    this.props.onFilterChange('locationDisplayName', '')
    this.setState({ value: null })
  }

  handleChange = e => {
    geocodeByAddress(e.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.props.onFilterChange('lat', lat)
        this.props.onFilterChange('lng', lng)
        this.props.onFilterChange('locationDisplayName', e.label)
        this.setState({ value: e })
        this.inputRef.current.blur()
      })
  }

  render() {
    return (
      <div className="LocationInput Form--stack" ref={this.formLocation}>
        <div className="LocationInput--heading">
          <label>Location</label>
        </div>
        <div className="LocationInput--algolia">
          <GooglePlacesAutocomplete
            apiKey="AIzaSyAvOjlvCXr-iTHlKmutLq-CA0GaTIviI_Y"
            selectProps={{
              value: this.state.value,
              ref: this.inputRef,
              onChange: this.handleChange,
              onFocus: () => {
                this.props.setActive('location')
              },
              placeholder: 'Enter Location',
              styles: {
                control: provided => ({
                  ...provided,
                  background: 'transparent',
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '0.9rem',
                  fontWeight: '400',
                  color: 'black',
                  alignItems: 'flex-end',
                  boxShadow: 'none !important',
                  padding: '0px',
                }),
                placeholder: provided => ({
                  ...provided,
                  fontFamily: 'Montserrat',
                  fontSize: '0.9rem',
                  fontWeight: '400',
                  marginLeft: '0px',
                  color: 'black',
                }),
                input: provided => ({
                  ...provided,
                  color: 'black',
                  padding: '0px',
                  fontFamily: 'Montserrat',
                  fontSize: '0.9rem',
                  fontWeight: '400',
                }),
                valueContainer: provided => ({
                  ...provided,
                  color: 'black',
                  padding: '0px',
                }),
                menu: provided => ({
                  ...provided,
                  color: 'black',
                  padding: '0px',
                  zIndex: '900',
                  border: 'none',
                  boxShadow: '0px 1px 5px #c3d0d8',
                }),
                menuList: provided => ({
                  ...provided,
                  color: 'black',
                  padding: '0px',
                  zIndex: '900',
                }),
                option: provided => ({
                  ...provided,
                  color: 'black',
                }),
                noOptionsMessage: provided => ({
                  ...provided,
                  display: 'none',
                }),
                indicatorSeparator: provided => ({
                  ...provided,
                  display: 'none',
                }),
                indicatorsContainer: provided => ({
                  ...provided,
                  display: 'none',
                }),
                singleValue: provided => ({
                  ...provided,
                  color: 'black',
                  padding: '0px',
                }),
              },
            }}
          />
        </div>
        <Button className="Button--clear" onClick={this.handleClear} type="button"></Button>
      </div>
    )
  }
}
