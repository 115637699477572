import axios from 'axios'
import React, { Component, Fragment } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { Redirect } from 'react-router'
import Slider from 'react-slick'
import { disableChat } from '../../../utility/chat'
import { Contact, Testimonial } from '../components'
import T from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'

// Import css files
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

function CertificationsLoader() {
  return (
    <div className="flex items-start justify-start h-12 Profile">
      <div className="flex flex-col items-center">
        <span className="spinner"></span>
        <span className="block font-bold tracking-widest mt-4 uppercase text-md">Loading certifications...</span>
      </div>
    </div>
  )
}

function Tooltip({ children, Overlay }) {
  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <T
      class="rc-tool"
      placement="right"
      overlay={<Overlay />}
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      overlayInnerStyle={{
        backgroundColor: '#ee3662',
        border: 'solid 1px #fff',
        borderRadius: '14px',
        boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.1)',
        fontSize: '1rem',
      }}
      onClick={handleClick}
    >
      {children}
    </T>
  )
}

function BadgeToolTip({ certificationName, imgSrc }) {
  return (
    <div className="relative flex items-center justify-center mb-0 text-base uppercase lg:justify-start">
      <Tooltip
        Overlay={() => (
          <p className="m-0 p-0 text-base uppercase">
            <span className="font-bold">{certificationName}</span> Certified Coach
          </p>
        )}
      >
        <img className="w-16 h-16 mr-4" src={imgSrc} />
      </Tooltip>
    </div>
  )
}

const GRANT_WHITELIST = ['HAC.LEGACY', 'CB.FLIGHTPLAN.CERTIFIED', 'CB.HOSP.CERTIFIED', 'CB.FOCUS.CERTIFIED']
const CERTIFICATION_GRANTS_LOOKUP = {
  'HAC.LEGACY': {
    imgSrc: require('../../../assets/coach-badge-2020.svg').default,
    certificationName: 'Business Made Simple',
  },
  'CB.FLIGHTPLAN.CERTIFIED': {
    imgSrc: require('../../../assets/sbfp-badge.png').default,
    certificationName: 'Small Business Flight Plan',
  },
  'CB.HOSP.CERTIFIED': {
    imgSrc: require('../../../assets/uh-badge.png').default,
    certificationName: 'Unreasonable Hospitality',
  },
  'CB.FOCUS.CERTIFIED': {
    imgSrc: require('../../../assets/ff-badge.png').default,
    certificationName: 'Full Focus',
  },
}

function Certification({ imgSrc, certificationName }) {
  return (
    <div className="relative flex lg:flex-row flex-col lg:items-center justify-start lg:mb-4 mb-0">
      <img className="w-16 h-16 mr-4 lg:mb-0 mb-2" src={imgSrc} />
      <p className="inline-block uppercase m-0 p-0">
        <span className="mr-2 font-bold">{certificationName} </span>
        Certified Coach
      </p>
    </div>
  )
}

const profilePageLoadEventArguments = {
  dataLayer: { event: 'loadProfilePage' },
  dataLayerName: 'CYMDataLayer',
}

class Profile extends Component {
  constructor(props) {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
    }

    super(props)
    this.state = {
      profile: props.profile ? props.profile : null,
      referral_code: null,
      noMatch: false,
      fixAside: false,
      loadingGrants: true,
      profileGrants: [],
    }
    this.contactForm = React.createRef()

    this.slider = React.createRef()
    if (props.modalProfileId) {
      // this.loadProfile(props.modalProfileId)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    } else {
      if (!props.isMMS) {
        this.loadReferral(props.match.params.slug)
      }
      this.loadProfile(props.match.params.slug)
      this.loadGrants(props.match.params.slug)
    }

    TagManager.dataLayer(profilePageLoadEventArguments)
    disableChat()
  }

  handleResize = () => {
    const fixAside = window.innerWidth >= 1024
    if (this.state.fixAside !== fixAside) this.setState({ fixAside })
  }

  componentDidMount() {
    let profileSlug = this.props.modalProfileId ? this.props.modalProfileId : this.props.match.params.slug
    axios.post(process.env.REACT_APP_API_LOCATION + `/api/profile-view/${profileSlug}`)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalProfileId) {
      return
    }
    let {
      location: { pathname },
    } = this.props

    if (prevProps.location.pathname === pathname) return
    if (!this.props.isMMS) {
      this.loadReferral(this.props.match.params.slug)
    }
    this.loadProfile(this.props.match.params.slug)
    this.loadGrants(this.props.match.params.slug)
  }

  trackWebsiteClick = () => {
    axios.post(
      process.env.REACT_APP_API_LOCATION +
        `/api/external-website-view/${
          this.props.modalProfileId ? this.props.modalProfileId : this.props.match.params.slug
        }`
    )
  }

  loadGrants = slug => {
    axios
      //grab profile & grants
      .get(process.env.REACT_APP_API_LOCATION + '/api/profile-grants?slug=' + encodeURIComponent(slug))
      .then(response => {
        this.setState({ loadingGrants: false, profileGrants: response.data.grants })
      })
      .catch(function (error) {
        this.setState({ loadingGrants: false })
        console.log(error)
      })
  }

  loadProfile = slug => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    axios
      //grab profile & grants
      .get(process.env.REACT_APP_API_LOCATION + '/api/profiles?slug=' + encodeURIComponent(slug))
      .then(response => {
        if (response.data.total) {
          this.setState({ profile: response.data.data[0] })
        } else {
          this.setState({ noMatch: true })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  loadReferral = slug => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + `/api/profile/referral/${encodeURIComponent(slug)}`)
      .then(({ data, status }) => {
        if (status === 200 && !!data.success) {
          this.setState({ referral_code: data.referral_code })
        }
      })
  }

  render() {
    const { profile, referral_code } = this.state
    const { isMMS, modalProfileId } = this.props

    if (this.state.noMatch) {
      return <Redirect to="/404" />
    }

    let averageCost = ''
    if (profile) {
      switch (profile.price) {
        case 1:
          averageCost = '$500'
          break
        case 2:
          averageCost = '$1,500'
          break
        case 3:
          averageCost = '$5,000'
          break
        case 4:
          averageCost = '$10,000'
          break
        default:
          averageCost = '$10,000'
      }
    }

    if (!this.state.profile) {
      return (
        <div className="flex items-center justify-center h-banner Profile">
          <div className="flex flex-col items-center">
            <span className="spinner"></span>
            <span className="block mt-6 font-bold tracking-widest uppercase text-md">Loading profile...</span>
          </div>
        </div>
      )
    }

    const renderHAC = () => {
      return (
        <div className="Profile">
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {'Hire A Coach'} | {profile.first_name} {profile.last_name}
            </title>
          </Helmet>
          <section className="mb-12 lg:mb-24">
            <div className="w-100">
              <img
                className="object-fit w-full max-h-96"
                alt="Custom Profile Image"
                src={
                  profile.hero_image_url
                    ? profile.hero_image_url
                    : require('../../../assets/coach-hero-image.jpg').default
                }
              />
            </div>
            <div className="hidden px-6 py-4 bg-white border-b border-gray-200 w-100 lg:block">
              <div className="relative mx-auto max-w-7xl flex lg:pl-52">
                <div className="absolute left-0 w-48 h-48 overflow-hidden transform -translate-y-1/2 border border-gray-100 border-solid rounded-full shadow-sm top-1/2">
                  <img className="object-cover" src={profile.image_url} alt="Profile photo" />
                </div>

                {this.state.loadingGrants && <CertificationsLoader />}
                {this.state.profileGrants.map(certification => {
                  if (!GRANT_WHITELIST.includes(certification.grant)) return <Fragment></Fragment>
                  return (
                    <BadgeToolTip
                      key={`${certification.id}-desktop`}
                      imgSrc={CERTIFICATION_GRANTS_LOOKUP[certification.grant].imgSrc}
                      certificationName={CERTIFICATION_GRANTS_LOOKUP[certification.grant].certificationName}
                    />
                  )
                })}
              </div>
            </div>
          </section>
          <section className="px-6 mb-12 md:px-10">
            <div className="grid grid-cols-1 mx-auto lg:grid-cols-3 max-w-7xl lg:gap-x-6">
              <aside className="flex flex-col-reverse items-stretch justify-between col-span-1 mb-12 md:flex-row lg:block lg:mb-0">
                <div className="flex-shrink-0 mr-4">
                  <div className="text-center md:text-left">
                    <div>
                      <h4 className="text-3xl font-bold text-center md:text-left">
                        {profile.first_name} {profile.last_name}
                      </h4>
                      <p className="mb-0 text-lg italic">{profile.location}</p>
                      <p className="text-base font-bold uppercase text-gold">
                        {!!profile.certification_date &&
                          'Coach Builder Certified since ' + new Date(profile.certification_date).getFullYear()}
                      </p>
                    </div>
                    <div className="my-8">
                      {!!!modalProfileId && (
                        <div>
                          <a
                            name="contact"
                            className="btn btn--primary"
                            role="button"
                            onClick={() => {
                              window.scrollTo({ top: this.contactForm.current.offsetTop, behavior: 'smooth' })
                            }}
                          >
                            Contact Me
                          </a>
                        </div>
                      )}
                      {!!profile.website && (
                        <div className="mt-3">
                          <a
                            className="btn btn--primary-ghost"
                            href={profile.website.indexOf('://') === -1 ? 'http://' + profile.website : profile.website}
                            onClick={this.trackWebsiteClick}
                            target="_blank"
                          >
                            Visit Website
                          </a>
                        </div>
                      )}
                      {!!referral_code && (
                        <div className="mt-3">
                          <a
                            className="btn btn--primary"
                            target="_blank"
                            aria-label="external link to mybusinessreport.com"
                            href={`https://mybusinessreport.com/assessment?ref=${referral_code}`}
                          >
                            Take the Assessment
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  {profile.work_options && profile.work_options.length ? (
                    <div>
                      <h3 className="text-lg tracking-widest uppercase">Ways We Can Work Together</h3>
                      <ul>
                        {profile.work_options.map(item => (
                          <li className="flex items-center text-base">
                            <img
                              className="mr-2"
                              alt="Check Mark"
                              src={require('../../../assets/check-mark-green.svg').default}
                            />
                            <span className="text-black">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div className="flex items-center justify-center w-full mb-4 text-center lg:hidden">
                  <div className="flex flex-col items-center">
                    <div className="relative flex justify-center mb-4">
                      <div className="w-48 h-48 overflow-hidden border border-gray-100 border-solid rounded-full shadow-sm">
                        <img className="object-cover" src={profile.image_url} alt="Profile photo" />
                      </div>
                      {/* <img
                        className="absolute left-0 w-16 h-16 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full top-1/2"
                        src={require('../../../assets/coach-badge-2020.svg').default}
                      /> */}
                    </div>
                    {/* <p className="text-sm uppercase">
                      <span className="block mr-2 font-bold">Business Made Simple</span>
                      <span>Certified Coach</span>
                    </p> */}
                  </div>
                </div>
              </aside>

              <div className="col-span-2 content">
                <section className="mb-16">
                  <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                    About
                  </h3>
                  <p className="text-base" dangerouslySetInnerHTML={{ __html: profile.pitch }} />
                </section>

                <section className="mb-16">
                  <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                    Certifications
                  </h3>

                  {this.state.loadingGrants && <CertificationsLoader />}
                  {this.state.profileGrants.map(certification => {
                    if (!GRANT_WHITELIST.includes(certification.grant)) return <Fragment></Fragment>

                    return (
                      <Certification
                        key={certification.id}
                        imgSrc={CERTIFICATION_GRANTS_LOOKUP[certification.grant].imgSrc}
                        certificationName={CERTIFICATION_GRANTS_LOOKUP[certification.grant].certificationName}
                      />
                    )
                  })}
                </section>

                {profile.services && profile.services.length ? (
                  <section className="mb-16">
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Services Offered
                    </h3>
                    <ul>
                      {profile.services.map((service, i) => (
                        <li key={i}>{service.name}</li>
                      ))}
                    </ul>
                  </section>
                ) : null}

                {profile.industries && profile.industries.length ? (
                  <section className="mb-16">
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Industries
                    </h3>
                    <ul>
                      {profile.industries.map((ind, i) => (
                        <li key={i}>{ind.name}</li>
                      ))}
                    </ul>
                  </section>
                ) : null}

                {/* {profile.businessTypes && profile.businessTypes.length ? (
                  <section className="mb-16">
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Business Types
                    </h3>
                    <ul>
                      {profile.businessTypes.map((b, i) => (
                        <li key={i} className={'businessTypes'}>
                          {b.name}
                        </li>
                      ))}
                    </ul>
                  </section>
                ) : null} */}

                {profile.testimonies && profile.testimonies.length ? (
                  <section className="mb-16">
                    <h3 className="mb-6 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Testimonials
                    </h3>
                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                      {profile.testimonies
                        .sort((a, b) => +a.sort - +b.sort)
                        .map((testimony, i) => (
                          <div>
                            <Testimonial key={i} testimony={testimony} />
                          </div>
                        ))}
                    </div>
                  </section>
                ) : null}

                {modalProfileId ? null : (
                  <section className="mt-16" ref={this.contactForm}>
                    <h3 className="mb-4 text-lg tracking-widest uppercase">Contact Me</h3>
                    <Contact profile={profile} isMMS={isMMS} />
                  </section>
                )}
              </div>
            </div>
          </section>
        </div>
      )
    }

    return renderHAC()
  }
}

export default Profile
