import React, { Component } from 'react'

class Skills extends Component {
    handleSkillsUpdate = e => {
        const { onFilterChange, selectedSkills } = this.props
        const skill = e.target.value
        const updated = selectedSkills.includes(skill)
            ? selectedSkills.filter(s => s !== skill)
            : selectedSkills.concat([skill])
        onFilterChange('skills', updated)
    }

    render() {
        const { allSkills, selectedSkills } = this.props

        return (
            <div className="SkillsFilter">
                {allSkills.map(skill => (
                    <div key={skill.id} className="SkillsFilterCheckbox">
                        <input
                            type="checkbox"
                            id={`skill-${skill.id}`}
                            name={`skill-${skill.id}`}
                            value={skill.id}
                            onChange={this.handleSkillsUpdate}
                            checked={selectedSkills.includes(skill.id.toString())}
                        />
                        <label htmlFor={`skill-${skill.id}`}>{skill.name}</label>
                    </div>
                ))}
            </div>
        )
    }
}

export default Skills
