import React, { Component } from 'react';

class Budget extends Component {
    handleChange = (e) => {
        this.props.onFilterChange('budget', e.target.value);
    };

    render() {
        return (
            <div className="ratings-wrapper">
                <span className="ratings ratings--dollar">
                    <input type="radio" id="price_4" name="price" value="4" onChange={this.handleChange} checked={this.props.budget === "4"}/><label htmlFor="price_4">4<div className="tooltip">Projects starting at $10,000</div></label>
                    <input type="radio" id="price_3" name="price" value="3" onChange={this.handleChange} checked={this.props.budget === "3"}/><label htmlFor="price_3">3<div className="tooltip">Projects starting at $5,000</div></label>
                    <input type="radio" id="price_2" name="price" value="2" onChange={this.handleChange} checked={this.props.budget === "2"}/><label htmlFor="price_2">2<div className="tooltip">Projects starting at $1,500</div></label>
                    <input type="radio" id="price_1" name="price" value="1" onChange={this.handleChange} checked={this.props.budget === "1"}/><label htmlFor="price_1">1<div className="tooltip">Projects starting at $500</div></label>
                </span>
                <input type="radio" id="price_all" name="price" value="" onChange={this.handleChange} checked={this.props.budget === ""}/><label htmlFor="price_all">ALL</label>
            </div>)
    }
}

export default Budget;