import React, { Component } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'

class FilterOptions extends Component {
  constructor(props) {
    super(props)

    this.inputRef = React.createRef()
  }

  state = {
    value: null,
  }

  componentDidMount() {
    const { filters } = this.props
    if (filters && filters.locationDisplayName) {
      this.setState({ value: filters.locationData })
    }
  }

  handleUpdate = e => {
    const { onFilterChange, selectedOptions, limit } = this.props
    const option = e.target.value

    if (option === 'all') {
      selectedOptions.includes('all')
        ? onFilterChange(this.props.filter, [])
        : onFilterChange(this.props.filter, ['all'])
    } else {
      if (limit && selectedOptions.length >= limit && !selectedOptions.includes(option)) return

      const updated = selectedOptions.includes(option)
        ? selectedOptions.filter(s => s !== option)
        : selectedOptions.concat([option]).filter(s => s !== 'all')
      onFilterChange(this.props.filter, updated)
    }
  }

  handleClear = () => {
    this.props.onFilterChange('lat', '')
    this.props.onFilterChange('lng', '')
    this.props.onFilterChange('locationDisplayName', '')
    this.setState({ value: null })
  }

  handleChange = e => {
    if (!e) {
      this.handleClear()
      return
    }
    geocodeByAddress(e.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.props.onFilterChange('lat', lat)
        this.props.onFilterChange('lng', lng)
        this.props.onFilterChange('locationDisplayName', e.label)
        this.props.onFilterChange('locationData', e)
        this.setState({ value: e })
        this.inputRef.current.blur()
      })
  }

  disabled = option => {
    const { selectedOptions, limit } = this.props

    if (limit && selectedOptions.length >= limit && !selectedOptions.includes(option.toString())) {
      return true
    }

    if (selectedOptions.includes('all')) return true

    return false
  }

  isSelected = option => {
    const { selectedOptions } = this.props
    return selectedOptions.includes(option.id && option.id.toString())
  }

  render() {
    const { allOptions, selectedOptions, step, limit } = this.props

    return (
      <div className={`SkillsFilter ${step === 4 ? 'two-columns' : 'one-column'}`}>
        {allOptions.map(option => (
          <div
            key={option.id}
            className={`SkillsFilterCheckbox hac ${selectedOptions.includes('all') ? 'disabled' : ''}`}
          >
            <input
              type="checkbox"
              id={`skill-${option.id}`}
              name={`skill-${option.id}`}
              value={option.id}
              onChange={this.handleUpdate}
              checked={this.isSelected(option)}
            />
            <label
              className={`label-bold label-black ${option.description ? 'align-top' : ''} ${
                this.disabled(option.id) ? 'label-disabled' : ''
              }`}
              htmlFor={`skill-${option.id}`}
            >
              {option.display_name ? option.display_name : option.name}
              {option.description && <p className={`option-description`}>{option.description}</p>}
            </label>
            {step === 1 && option.id === 2 && (
              <div
                className={`location ${
                  this.isSelected(option) || selectedOptions.includes('all') ? 'location--show' : 'show'
                }`}
              >
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAvOjlvCXr-iTHlKmutLq-CA0GaTIviI_Y"
                  selectProps={{
                    isClearable: true,
                    value: this.state.value,
                    ref: this.inputRef,
                    onChange: this.handleChange,
                    clearValue: this.handleClear,
                    placeholder: 'Enter Location',
                    styles: {
                      control: (provided, state) => ({
                        ...provided,
                        background: 'transparent',
                        fontFamily: 'Montserrat',
                        fontSize: '0.9rem',
                        fontWeight: '400',
                        color: 'black',
                        boxShadow: 'none !important',
                        border: '2px solid #2d3354 !important',
                        border: state.isFocused ? '3px solid #2d3354 !important' : '2px solid #2d3354 !important',
                        cursor: 'text',
                        minWidth: '250px',
                      }),
                      placeholder: provided => ({
                        ...provided,
                        fontFamily: 'Montserrat',
                        fontSize: '0.9rem',
                        fontWeight: '400',
                      }),
                      input: provided => ({
                        ...provided,
                        color: 'black',
                        fontFamily: 'Montserrat',
                        fontSize: '0.9rem',
                        fontWeight: '400',
                      }),
                      valueContainer: provided => ({
                        ...provided,
                        color: 'black',
                      }),
                      menu: provided => ({
                        ...provided,
                        color: 'black',
                        zIndex: '900',
                        border: 'none',
                        boxShadow: '0px 1px 5px #c3d0d8',
                      }),
                      menuList: provided => ({
                        ...provided,
                        color: 'black',
                        padding: '0px',
                        zIndex: '900',
                      }),
                      option: provided => ({
                        ...provided,
                        color: 'black',
                      }),
                      noOptionsMessage: provided => ({
                        ...provided,
                        display: 'none',
                      }),
                      clearIndicator: provided => ({
                        ...provided,
                        border: '2x solid black',
                      }),
                      indicatorSeparator: provided => ({
                        ...provided,
                        display: 'none',
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        display: 'none',
                      }),
                      indicatorsContainer: provided => ({
                        ...provided,
                      }),
                      singleValue: provided => ({
                        ...provided,
                        color: 'black',
                        padding: '0px',
                      }),
                    },
                  }}
                />
              </div>
            )}
          </div>
        ))}

        {!limit && (
          <div key="all" className="SkillsFilterCheckbox hac">
            <input
              type="checkbox"
              id={`skill-all`}
              name={`skill-all`}
              value={'all'}
              onChange={this.handleUpdate}
              checked={selectedOptions.includes('all')}
            />
            <label className="label-bold label-black" htmlFor={`skill-all`}>
              All of the above
            </label>
          </div>
        )}
      </div>
    )
  }
}

export default FilterOptions
