import React, { Component } from 'react'
import { capitalizeEachWord } from '../../../../utility/Capitalize'

class Certifications extends Component {
    handleCertsUpdate = e => {
        const { onFilterChange, selectedCerts } = this.props
        const cert = e.target.value
        const updated = selectedCerts.includes(cert)
            ? selectedCerts.filter(s => s !== cert)
            : selectedCerts.concat([cert])
        onFilterChange('certs', updated)
    }

    render() {
        const { allCerts, selectedCerts } = this.props
        return (
            <div className="SkillsFilter certs">
                {allCerts.map(cert => (
                    <div key={cert.id} className="SkillsFilterCheckbox">
                        <input
                            type="checkbox"
                            id={`cert-${cert.id}`}
                            name={`cert-${cert.id}`}
                            value={cert.id}
                            onChange={this.handleCertsUpdate}
                            checked={selectedCerts.includes(cert.id.toString())}
                        />
                        <label htmlFor={`cert-${cert.id}`}>{capitalizeEachWord(cert.name)}</label>
                    </div>
                ))}
            </div>
        )
    }
}

export default Certifications
