export function capitalizeEachWord(string = null) {
  if (!string) return "";

  const split = string.split(" ");

  const capitalized = split.map((word) => {
    return word.charAt(0).toUpperCase() + word.substring(1);
  });

  return capitalized.join(" ");
}
