import React, { useState, Fragment } from 'react'
import FiltersHeader from './FiltersHeader'
import Button from '../../shared/Button'
import { FilterOptions } from '../../search/components/filters'

const QuestionBox = ({
    nameOnly,
    isMMS,
    step,
    nextStep,
    previousStep,
    questions,
    options,
    selectedOptions,
    onFilterChange,
    filter,
    filters,
    renderProfiles,
    renderLoadingIndicator,
    handleNext,
    selectedContacts,
    nextPage,
    prevPage,
    handleSearch,
    loading,
}) => {
    const validated = () => {
        let valid = true

        if (step === 1) {
            if (selectedOptions.includes('2') && !filters.lat) {
                valid = false
            }
            if (selectedOptions.includes('all') && !filters.lat) {
                valid = false
            }
            if (!filters.work_options.length) {
                valid = false
            }
        }

        if (step === 2 && !filters.services.length) {
            valid = false
        }

        if (step === 3 && !filters.course_preferences.length) {
            valid = false
        }

        if (step === 4 && !filters.industries.length) {
            valid = false
        }

        if (step === 5 && !filters.business_types.length) {
            valid = false
        }

        if (
            step === 5 &&
            !filters.skills.length &&
            !filters.work_options.length &&
            !filters.services.length &&
            !filters.industries.length &&
            !filters.business_types.length &&
            !filters.course_preferences.length
        ) {
            valid = false
        }

        if (step === 6 && !selectedContacts.length) {
            valid = false
        }

        return valid
    }

    const [showValidationWarning, setShowValidationWarning] = useState(false)

    const handleMouseEnter = () => {
        if (!validated()) {
            setShowValidationWarning(true)
        }
    }

    const handleMouseLeave = () => {
        setShowValidationWarning(false)
    }

    let limit
    if (step === 3 || step === 4 || step === 5) limit = 3

    return (
        <div className="Question-box">
            {nameOnly ? (
                <FiltersHeader
                    filters={filters}
                    onFilterChange={onFilterChange}
                    handleSearch={handleSearch}
                    isMMS={isMMS}
                    nameOnly={nameOnly}
                />
            ) : (
                <Fragment>
                    <p className="step-counter">Step {step} of 7</p>
                    <h1 className="--color-black">{questions[step - 1]}</h1>
                    {step <= 5 && (
                        <Fragment>
                            <p>
                                <small>{limit ? <em>Choose up to {limit}</em> : <em>Choose all that apply</em>}</small>
                            </p>
                            <FilterOptions
                                filter={filter}
                                filters={filters}
                                allOptions={options}
                                onFilterChange={onFilterChange}
                                selectedOptions={selectedOptions}
                                step={step}
                                limit={limit}
                                validated={validated}
                            />
                        </Fragment>
                    )}
                </Fragment>
            )}
            {step === 6 && <div className="results">{renderProfiles()}</div>}
            {step === 6 && loading && <div style={{ width: '100%' }}>{renderLoadingIndicator()}</div>}

            {step === 6 && nextPage && (
                <div className="results">
                    <button className="next-results" onClick={() => handleNext(true)}>
                        {nextPage ? <span>Load More Coaches</span> : null}
                    </button>
                </div>
            )}

            {step === 6 || !nameOnly ? (
                <div className="button-wrapper">
                    <Button className="Button Button--ghost" onClick={previousStep}>
                        {nameOnly ? 'Reset' : 'Back'}
                    </Button>

                    {step === 6 && (
                        <Button
                            onClick={nextStep}
                            disabled={!validated()}
                            className={`Button Button--pink selected-count ${
                                selectedContacts.length ? '' : 'no-count'
                            }`}
                        >
                            {selectedContacts.length} <span>Coach{selectedContacts.length === 1 ? '' : 'es'}</span>{' '}
                            Selected
                        </Button>
                    )}

                    <Button
                        className={`Button Button--ghost ${validated() ? '' : 'disabled'}`}
                        onClick={validated() ? nextStep : () => {}}
                        onMouseOver={handleMouseEnter}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        Next
                    </Button>

                    <div className={`disclaimer ${showValidationWarning ? 'show' : ''}`}>
                        {selectedOptions &&
                        (selectedOptions.includes('2') || selectedOptions.includes('all')) &&
                        !filters.lat
                            ? 'You Must enter a City/Country or Zip Code'
                            : 'Please make at least one selection'}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default QuestionBox
