import * as React from 'react';

export default function BadUrl () {
    return (
        <div className="pw" data-st="st-row">
            <div data-st="st-twelve">
                <h1>Sorry!</h1>
                <p>You've reached an invalid page.</p>
            </div>
        </div>
    )
}