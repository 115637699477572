/* global zE, $zopim */

export function enableChat(depName = 'Marketing Made Simple') {
  function onChatStatus(cb) {
    if (!zE) return
    zE(() => {
      if (!$zopim) return
      zE.show()
      $zopim(() => $zopim.livechat && $zopim.livechat.setOnConnected(() => $zopim.livechat.setOnStatus(cb)))
    })
  }

  onChatStatus(function () {
    if (!depName) return
    var department = $zopim.livechat.departments.getDepartment(depName)

    if (!department || department.status === 'offline') {
      $zopim.livechat.setStatus('offline')
      $zopim.livechat.hideAll()
      return
    }

    $zopim.livechat.addTags(depName.toLowerCase().replace(/\s+/g, '_') + '_chat')
    $zopim.livechat.departments.filter('')
    $zopim.livechat.departments.setVisitorDepartment(depName)
  })
}

export function disableChat() {
  if (!zE) return
  zE(function () {
    zE.hide()
  })
}
