import * as React from 'react';

export default function Testimonial (props) {
    return (
        <div className="Testimonial" data-st="st-four">
            <div className="Card__copy">
                &ldquo;{props.children}&rdquo;
            </div>
            <div className="Card__attribution">{props.author}</div>
        </div>
    )
}