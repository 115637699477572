import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Button extends Component {
    render() {
        const { to, onClick, className, type, disabled, children, onMouseEnter, onMouseLeave } = this.props
        const Tag = to ? Link : 'button'
        return (
            <Tag
                className={className}
                to={to}
                disabled={disabled ? true : null}
                onClick={onClick ? onClick : null}
                type={type ? type : null}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {children}
            </Tag>
        )
    }
}
