import React from 'react'

const ProgressBar = ({ step, stepsCount, setStep, isAdmin }) => {
  return (
    <div className="progress-bar-wrapper">
      <div className="bar-grey">
        <div className="bar-gold" style={{ width: `${((step - 1) / (stepsCount - 1)) * 100}%` }}></div>
        {Array.from({ length: stepsCount }).map((dot, index) => {
          return (
            <button
              key={index}
              disabled={!isAdmin}
              // disabled={process.env.NODE_ENV === 'production'}
              onClick={() => setStep(index + 1)}
              className={`progress-dot ${step >= index + 1 && 'dot-gold'} ${step > index + 1 && 'dot-complete'}`}
            ></button>
          )
        })}
      </div>
    </div>
  )
}

export default ProgressBar
